import React from "react";
import Members from "./Components/Members";

const Overview = () => {
  return (
    <div className="overview">
      <Members/>
    </div>
  );
};

export default Overview;
