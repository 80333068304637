export const tabs = [
  {
    name: "Overview",
    route: ""
  },
  {
    name: "Transactions",
    route: "/transactions"
  },
  {
    name: "Fieldwork",
    route: "/fieldwork"
  }
];
