import React from "react";
import { RadioButton, RadioGroup } from "react-radio-buttons";

const Radio = ({
  placeholder,
  field = {},
  form = {},
  options,
  defaultValue
}) => {
  return (
    <div className="inputWrapper radio">
      <p className={"placeholder active"}>{placeholder}</p>
      <RadioGroup
        name={placeholder}
        className={"radioContainer"}
        value={defaultValue || field.value}
        selectedValue={field.value}
        onChange={value => form.setFieldValue(field.name, value)}
      >
        {options.map((option, i) => (
          <RadioButton
            value={option}
            key={placeholder + " " + i}
            disabledColor={"pink"}
          >
            <p>{option}</p>
          </RadioButton>
        ))}
      </RadioGroup>
      <p className={"error"}>
        {form.errors && form.touched[field.name] && form.errors[field.name]}
      </p>
    </div>
  );
};

export default Radio;
