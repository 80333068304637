import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Subscribe } from "unstated";
import date from "date-and-time";
// eslint-disable-next-line no-unused-vars
import meridiem from "date-and-time/plugin/meridiem";
import MessagingStore from "../../../Stores/MessagingStore";
import UserStore from "../../../../../App/Auth/Store/UserStore";

date.plugin("meridiem");

const ConversationElement = ({ user, lastMessage, id, match: { url } }) => {
  return (
    <Subscribe to={[MessagingStore, UserStore]}>
      {({ setActiveConversation }, { state: { id: userID } }) => (
        <Link to={`${url}/${id}`}>
          <div className="conversationElement">
            <div
              className="image"
              style={{
                background: `url(${user.image}) center center /cover no-repeat`
              }}
            />
            <div className="details">
              <div className="info">
                <div className="name">{user.name}</div>
                <div className="time">
                  {date.format(new Date(lastMessage.createdAt), "hh:mm AA")}
                </div>
              </div>

              <div className="lastMessage">
                {lastMessage.text ||
                  (lastMessage.files.length
                    ? `${
                        userID === lastMessage.user._id
                          ? "You"
                          : lastMessage.user.displayName
                      } sent an attachment`
                    : ``)}
              </div>
            </div>
          </div>
        </Link>
      )}
    </Subscribe>
  );
};

export default withRouter(ConversationElement);

ConversationElement.defaultProps = {
  lastMessage: {},
  user: {},
  id: ""
};
