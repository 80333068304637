import React from "react";
import { withRouter } from "react-router-dom";
import FloatingActionButton from "../../../Components/Buttons/FloatingActionButton";

const CreateProjectButton = ({ match: { url }, history }) => {
  return (
    <div className="createProjectButton">
      <FloatingActionButton
        onClick={() => {
          history.push(`${url}/create`);
        }}
        icon={"add"}
      />
    </div>
  );
};

export default withRouter(CreateProjectButton);
