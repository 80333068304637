import React, { useState } from "react";

const MessageElement = ({ style, text, received, createdAt }) => {
  const [showTime, toggleTime] = useState(false);
  return (
    <div
      className={"messageElement " + (received ? "received" : "sent")}
      style={style}
      onClick={() => toggleTime(!showTime)}
    >
      <div className="bubble">
        <p>{text}</p>
        <p
          className={"time"}
          style={{
            height: showTime ? "1.25em" : 0,
            marginTop: showTime ? "0.25em" : 0
          }}
        >
          <span /> {new Date(createdAt).toLocaleString()}
        </p>
      </div>
    </div>
  );
};

export default MessageElement;
