export const getContactSuggestions = (
  projects,
  currentProjectParticipants,
  filterAddedContacts = []
) =>
  projects
    .reduce((contacts, { participants }) => [...contacts, ...participants], [])
    .filter(
      ({ _id: currentID }, index, arr) =>
        arr.findIndex(({ _id }) => _id === currentID) === index
    )
    .filter(({ _id: currentID }) =>
      filterAddedContacts.length
        ? !filterAddedContacts.find(id => id === currentID)
        : !currentProjectParticipants.find(({ _id }) => _id === currentID)
    );
