import React from "react";
import Button from "../../../../../Components/Buttons/Button";

const InvestButtons = () => {
  return (
    <div className="investButtons">
      <Button onClick={() => {}} name={"ONE-TIME"} type={"primary"} />
      <Button onClick={() => {}} name={"SIP"} type={"primary"} />
    </div>
  );
};

export default InvestButtons;
