import React, { useState } from "react";
import { Subscribe } from "unstated";
import ProjectsStore from "../../../../../../../Store/ProjectsStore";
import { getContactSuggestions } from "../Helpers";
import UserStore from "../../../../../../../../Auth/Store/UserStore";

const PickFromAddedContacts = ({ setUser, filterAddedContacts }) => {
  const [submitting, setSubmitting] = useState(false);

  return (
    <Subscribe to={[ProjectsStore, UserStore]}>
      {(
        { state: { projects, activeProject }, getUser },
        { state: { id: userID } }
      ) => {
        return (
          <div
            className="pickFromAddedContacts"
            style={submitting ? { pointerEvents: "none" } : {}}
          >
            <p>or</p>
            <h1>Pick from ContractFlo contacts</h1>
            <div className="list">
              {getContactSuggestions(
                projects,
                [...activeProject.participants, { _id: userID }],
                filterAddedContacts ? [...filterAddedContacts, userID] : []
              ).map(({ displayName, displayPicture, phone }, i) => (
                <div
                  className="member"
                  key={`members-list-item-${i}`}
                  onClick={async () => {
                    setSubmitting(true);
                    await setUser(await getUser({ phone }, true));
                    setSubmitting(false);
                  }}
                >
                  <div
                    className="icon"
                    style={{
                      background: `url(${displayPicture.thumbUrl}) center center /cover no-repeat`
                    }}
                  />
                  <div className="info">
                    <p className="name">{displayName}</p>
                    <p className="contact">{phone}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      }}
    </Subscribe>
  );
};

export default PickFromAddedContacts;

PickFromAddedContacts.defaultProps = {
  filterAddedContacts: []
};
