import { Container } from "unstated";
import { loginApi, registerApi } from "Api/UserAuth";
import { firebase } from "Api/Configuration";
import { notify } from "Modules/ToastNotification";
import { parseRegistrationData } from "App/Auth/Helpers";

const initialState = () => ({
  loading: true,
  loadFailed: false,
  uid: "",
  id: "",
  user: {
    name: "",
    email: "",
    emailVerified: "",
    phone: "",
    other: {}
  },
  loggedIn: false
});

export default class UserStore extends Container {
  name = "UserStore";

  state = initialState();

  linkedStores = {};

  init = async () => {
    console.log("initializing UserStore");

    await new Promise(resolve =>
      firebase.auth().onAuthStateChanged(async existingUser => {
        if (existingUser) {
          const { uid } = existingUser;

          await this.logInUser(uid);
        } else {
          this.logout();
        }
        resolve(existingUser);
      })
    );

    await this.setState({ loading: false });

    return true;
  };

  passwordAuth = async data => {
    await firebase
      .auth()
      .signInWithEmailAndPassword(data.email, data.password)
      .catch(error => notify("error", error.message));
  };

  register = async ({ password, ...userData }) => {
    const data = parseRegistrationData(userData);

    const {
      user: { uid }
    } = await firebase
      .auth()
      .createUserWithEmailAndPassword(data.email, password);

    if (uid) {
      const success = await registerApi({ ...data, firebaseUid: uid });
      if (success) {
        await this.logInUser(uid);
      }
    } else {
      notify("error", "Failed to register");
    }
  };

  logInUser = async uid => {
    const { uid: existingUid } = this.state;

    if (existingUid === uid) {
      return;
    } else if (existingUid) {
      await this.logout();
    }
    const user = await loginApi(uid);

    if (user) {
      const { uid, id, ...userData } = user;
      window.sessionStorage.setItem("uid", uid);
      window.sessionStorage.setItem("user", id);
      await this.setState({
        uid,
        id,
        user: userData,
        loggedIn: !!uid
      });
    } else {
      alert("failed to login");
    }
  };

  logout = async () => {
    if (firebase.auth().currentUser) {
      await firebase.auth().signOut();
    }
    await this.setState(initialState());
  };

  bindStore = store => {
    this.linkedStores[store.name] = store;
  };
}
