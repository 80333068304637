import React from "react";
import { withRouter } from "react-router-dom";
import { Field, FieldArray, Form, withFormik } from "formik";
import { generateOptions } from "../../../../../../../../../Helpers/Misc";
import Input from "../../../../../../../../../Components/Input/Input";
import { transactionCurrencies, transactionTypes } from "../Data";
import Button from "../../../../../../../../../Components/Buttons/Button";
import {
  isRequired,
  string
} from "../../../../../../../../../Helpers/Form Validations/Validation Error Messages";
import * as Yup from "yup";
import { getTotalAmount } from "../Helpers";
import withStore from "../../../../../../../../../Components/Unstated/withStore";
import ProjectsStore from "../../../../../../../Store/ProjectsStore";
import ContactPicker from "./ContactPicker";

const TransactionForm = ({ values, isSubmitting, handleSubmit }) => {
  return (
    <div className="transactionForm">
      <Form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <h2>Category</h2>
        <Field
          name="category"
          render={props => (
            <Input
              {...props}
              type="select"
              options={generateOptions(transactionTypes)}
              isSearchable={false}
            />
          )}
        />
        <h2>Contacts</h2>
        <FieldArray
          name="contacts"
          render={arrayHelpers => (
            <ContactPicker arrayHelpers={arrayHelpers} values={values} />
          )}
        />
        <h2>Items</h2>
        <FieldArray
          name="items"
          render={arrayHelpers => (
            <div className="itemArray">
              {values.items &&
                values.items.map((item, i) => (
                  <div key={"items-" + i} className="itemArrayUnit">
                    <div className="index">{i + 1}.</div>
                    <div className="content">
                      <div className="row">
                        <Field
                          name={`items.${i}.description`}
                          render={props => (
                            <Input {...props} placeholder="Item Description" />
                          )}
                        />
                      </div>
                      <div className="row">
                        <Field
                          name={`currency`}
                          render={props => (
                            <Input
                              {...props}
                              placeholder="Currency"
                              type="select"
                              options={generateOptions(
                                transactionCurrencies,
                                "name",
                                "symbol"
                              )}
                              isSearchable={false}
                            />
                          )}
                        />
                        <Field
                          name={`items.${i}.price`}
                          render={props => (
                            <Input
                              {...props}
                              placeholder="Amount"
                              type={"number"}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="actions">
                      {values.items.length > 1 && (
                        <div
                          className="delete"
                          onClick={() => arrayHelpers.remove(i)}
                        />
                      )}
                    </div>
                  </div>
                ))}
              <h2 className="total">
                Total:{" "}
                <span>{getTotalAmount(values.items, values.currency)}</span>
              </h2>
              <Button
                onClick={() =>
                  arrayHelpers.push({
                    description: "",
                    price: ""
                  })
                }
                name={"Add Item"}
                type={"secondary"}
              />
            </div>
          )}
        />
        <Button
          onClick={() => handleSubmit()}
          name={"Submit"}
          type={"primary"}
          buttonType={"submit"}
          disabled={isSubmitting}
          loading={isSubmitting}
        />
      </Form>
    </div>
  );
};

export default withStore([ProjectsStore])(
  withRouter(
    withFormik({
      mapPropsToValues: () => ({
        category: transactionTypes[0],
        currency: transactionCurrencies[0].symbol,
        items: [{ description: "", price: "" }],
        contacts: []
      }),
      validationSchema: () =>
        Yup.object().shape({
          category: Yup.string(string).required(isRequired("Category")),
          currency: Yup.string(string).required(isRequired("Currency")),
          items: Yup.array()
            .of(
              Yup.object().shape({
                description: Yup.string(string).required(
                  isRequired("Item Description")
                ),
                price: Yup.string(string).required(isRequired("Amount"))
              })
            )
            .required(isRequired("At least 1 item")),
          contacts: Yup.array().of(
            Yup.object().shape({
              name: Yup.string(string).required(isRequired("Name")),
              id: Yup.string(string).required(isRequired("ID"))
            })
          )
        }),
      handleSubmit: async (
        values,
        {
          props: {
            projectsStore: { createTransaction },
            history,
            location: { pathname }
          },
          setSubmitting
        }
      ) => {
        const success = await createTransaction({
          ...values,
          users: values.contacts.map(({ id }) => id)
        });

        if (success) {
          history.push(
            pathname
              .split("/")
              .slice(0, -1)
              .join("/") + "/transactions"
          );
        } else {
          setSubmitting(false);
        }
      }
    })(TransactionForm)
  )
);
