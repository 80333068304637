import React from "react";
import date from "date-and-time";
import { fieldworkStatuses } from "../../Projects/Components/Project/Components/CreateFieldwork/Data";

const FieldworkListItem = ({
  projectName,
  description,
  createdAt,
  user: { displayName: userName },
  status
}) => {
  return (
    <div className="fieldworkListItem">
      <div className="content">
        <div className="header">
          <div className="icon" />
          <div className="info">
            <h1 className="name">{userName}</h1>
            <p className="time">
              <span className="icon" />
              {date.format(new Date(createdAt), "DD MMM YY")}
            </p>
          </div>
          <div className="projectInfo">
            <div className="icon" />
            <p className="name">{projectName}</p>
          </div>
        </div>
        {status && (
          <div className="status">
            <p>{fieldworkStatuses.find(({ code }) => code === status).name}</p>
          </div>
        )}

        <div className="footer">
          <div className="icon" />
          <p className="description">{description}</p>
        </div>
      </div>
      <div className="actions">
        <div className="delete">
          <div className="icon" />
        </div>
      </div>
    </div>
  );
};

export default FieldworkListItem;
