import UserStore from "App/Auth/Store/UserStore";
import AppStore from "App/Main/Store/AppStore";
import MessagingStore from "Modules/Messaging/Stores/MessagingStore";
import ProjectsStore from "../../App/Projects/Store/ProjectsStore";

export const linkedStores = () => {
  // Create instances of all stores which need linking here

  const stores = {
    userStore: new UserStore(),
    appStore: new AppStore(),
    messagingStore: new MessagingStore(),
    projectsStore: new ProjectsStore()
  };

  //Link all the stores

  //App Store
  stores.appStore.bindStore(stores.userStore);

  //Messaging Store
  stores.messagingStore.bindStore(stores.userStore);

  //Projects Store
  stores.projectsStore.bindStore(stores.userStore);

  return Object.keys(stores).map(key => stores[key]);
};
