import React from "react";
import { Link, withRouter } from "react-router-dom";
import Button from "Components/Buttons/Button";

const ProjectListItem = ({
  title,
  address,
  participants,
  id,
  match: { url },
  history
}) => {
  return (
    <div className="projectListItem">
      <Link to={`${url}/${id}`}>
        <div className="header">
          <h1 className="name">{title}</h1>
          <div className="info">
            <div className="icon" />
            <p>Investment</p>
          </div>
        </div>
        <div className="location">
          <div className="icon" />
          <p>{address}</p>
        </div>
      </Link>
      <div className="footer">
        <p className="participants">
          {participants.length} participant
          {participants.length > 1 ? "s" : ""}
        </p>
        <Link to={`${url}/${id}/addMember`}>
          <Button onClick={e => {}} name={"Add Member"} type={"primary"} />
        </Link>
      </div>
    </div>
  );
};

export default withRouter(ProjectListItem);
