import React from "react";
import "./CreateTransaction.sass";
import TransactionForm from "./Components/TransactionForm";

const CreateTransaction = () => {
  return (
    <div className="createTransaction">
      <h1>Create Transaction</h1>
      <TransactionForm />
    </div>
  );
};

export default CreateTransaction;
