import React from "react";
import ToastNotification from "Modules/ToastNotification/ToastNotification";

const Modules = () => {
  return (
    <div className="modules">
      '<ToastNotification />
    </div>
  );
};

export default Modules;
