import { Api } from "./Configuration";

export const getProjects = () => Api.post("project/getClientProjects");

export const createProject = ({ title, address, phone, participants }) =>
  Api.post("project/create", { title, address, phone, participants });

export const inviteToProject = data =>
  Api.post("invite/sendProjectInvite", data);

export const findOrCreateUser = data =>
  Api.post("users/findOrCreateUser/", data);

export const createProjectAction = data =>
  Api.post("project/action/create", data);
