import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "../../Styles/ChatInterface.sass";
import Heading from "./Components/Heading";
import MessageList from "./Components/MessageList";
import SendMessage from "./Components/SendMessage";
import { Portal } from "react-portal";
import withStore from "Components/Unstated/withStore";
import MessagingStore from "Modules/Messaging/Stores/MessagingStore";

const ChatInterface = ({
  match: {
    params: { id }
  },
  messagingStore: {
    state: { activeConversation },
    setActiveConversation
  }
}) => {
  useEffect(() => {
    setActiveConversation(id);
  }, [id, setActiveConversation]);

  return (
    activeConversation && (
      <Portal>
        <div
          className="chatInterface"
          key={activeConversation.conversation._id}
        >
          <Heading />
          <MessageList />
          <SendMessage />
        </div>
      </Portal>
    )
  );
};

export default withStore([MessagingStore])(withRouter(ChatInterface));
