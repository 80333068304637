import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import withStore from "../../../../Components/Unstated/withStore";
import InvestmentStore from "../../Stores/InvestmentStore";
import InvestmentHeader from "./Components/InvestmentHeader";
import ComponentLoader from "../../../../Components/Loading/ComponentLoader";
import InvestmentInfo from "./Components/InvestmentInfo";
import InvestmentStats from "./Components/InvestmentStats";
import InvestmentDescription from "./Components/InvestmentDescription";
import ProjectMilestones from "./Components/ProjectMilestones";
import InvestmentCalculator from "./Components/InvestmentCalculator";
import InvestButtons from "./Components/InvestButtons";

const Investment = ({
  investmentStore: {
    state: { loading, loadFailed, activeInvestment },
    setActiveInvestment
  },
  match: {
    params: { id }
  }
}) => {
  useEffect(() => {
    setActiveInvestment(id);
  }, [id, setActiveInvestment]);

  return (
    <div className="investment">
      <ComponentLoader
        loading={loading || !activeInvestment}
        loadFailed={loadFailed}
      >
        <>
          <InvestmentHeader />
          <InvestmentInfo />
          <InvestmentStats />
          <InvestmentDescription />
          <ProjectMilestones />
          <InvestmentCalculator />
          <InvestButtons />
        </>
      </ComponentLoader>
    </div>
  );
};

export default withStore([InvestmentStore])(withRouter(Investment));
