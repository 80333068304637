import React from "react";
import InvestmentStore from "../../../Stores/InvestmentStore";
import { Subscribe } from "unstated";
import date from "date-and-time";

const millisInDay = 24 * 60 * 60 * 1000;

const InvestmentStats = () => {
  return (
    <Subscribe to={[InvestmentStore]}>
      {({
        state: {
          activeInvestment: {
            milestones: {
              started,
              completed,
              durationTotal,
              durationNow,
              totalCost
            },
            investedAmount
          }
        }
      }) => (
        <div className="investmentStats">
          <div className="financial">
            <h1>{(investedAmount / totalCost).toFixed(2)} %</h1>
            <h2>Financed</h2>
            <p>₹ {investedAmount}</p>
            <h2>Project Cost</h2>
            <p>₹ {totalCost}</p>
          </div>
          <div className="time">
            <div className="dates">
              <div className="icon" />
              <div className="values">
                <p className="start">
                  {date.format(new Date(started), "DD MMM YY")}
                </p>
                <p className="end">
                  {date.format(new Date(completed), "DD MMM YY")}
                </p>
              </div>
            </div>
            <div className="remaining">
              <p>
                {Math.ceil((durationTotal - durationNow) / millisInDay)} days
                for completion.
              </p>
            </div>
          </div>
        </div>
      )}
    </Subscribe>
  );
};

export default InvestmentStats;
