import React from "react";
import "./Auth.sass";
import { Redirect, Switch, withRouter } from "react-router-dom";
import Route from "Components/Router/Route";
import Login from "App/Auth/Components/Login";
import Register from "App/Auth/Components/Register";

const Auth = ({ match: { url } }) => {
  return (
    <div className="auth">
      <Switch>
        <Route path={`${url}/login`} render={() => <Login />} />
        <Route path={`${url}/register`} render={() => <Register />} />
        <Route render={() => <Redirect to={`${url}/register`} />} />
      </Switch>
    </div>
  );
};

export default withRouter(Auth);
