import React from "react";
import date from "date-and-time";

const TransactionListItem = ({
  user: { displayName },
  price,
  category,
  createdAt,
  projectName,
  items
}) => {
  return (
    <div className="transactionListItem">
      <div className="content">
        <div className="header">
          <div className="icon" />
          <div className="info">
            <h1 className="user">{displayName}</h1>
            <p className="time">
              <span className="icon" />
              {date.format(new Date(createdAt), "DD MMM YY")}
            </p>
          </div>
          <p className="amount">{price}</p>
        </div>
        <div className="details">
          <h1 className="name">{projectName}</h1>
          <p className="category">{category}</p>
          <ol>
            {items.map(({ _id, description, price }, index) => (
              <li key={_id}>
                <span>
                  {index + 1}. {description}
                </span>
                <span>₹{price}</span>
              </li>
            ))}
          </ol>
        </div>
      </div>
      <div className="actions">
        <div className="delete">
          <div className="icon" />
        </div>
      </div>
    </div>
  );
};

export default TransactionListItem;
