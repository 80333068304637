import React from "react";
import ReactDOM from "react-dom";
import App from "./App/Main/App";
import * as serviceWorker from "./serviceWorker";
import {Provider} from "unstated";
import {linkedStores} from "./Helpers/Stores";

const stores = linkedStores();

ReactDOM.render(
  <Provider inject={stores}>
    <App/>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
