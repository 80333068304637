import React, { useEffect } from "react";
import "./Explore.sass";
import withStore from "../../Components/Unstated/withStore";
import InvestmentStore from "./Stores/InvestmentStore";
import ComponentLoader from "../../Components/Loading/ComponentLoader";
import { Switch, withRouter } from "react-router-dom";
import Route from "../../Components/Router/Route";
import InvestmentsList from "./Components/InvestmentList";
import Investment from "./Components/Investment";

const Explore = ({
  investmentStore: {
    state: { loading, loadFailed },
    init
  },
  match: { url }
}) => {
  useEffect(() => {
    init();
  }, [init]);

  return (
    <ComponentLoader loading={loading} loadFailed={loadFailed}>
      <div className="explore">
        <Switch>
          <Route exact path={`${url}`} render={() => <InvestmentsList />} />
          <Route path={`${url}/:id`} render={() => <Investment />} />
        </Switch>
      </div>
    </ComponentLoader>
  );
};

export default withStore([InvestmentStore])(withRouter(Explore));
