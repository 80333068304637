import React from "react";
import "./Button.sass";
import { Action, Fab } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";

const uniqid = require("uniqid");

const FloatingActionButton = ({ onClick, options = [] }) => {
  return (
    <div className="floatingActionButton">
      <Fab
        key={uniqid()}
        icon={<span className={"icon"}>+</span>}
        mainButtonStyles={{ backgroundColor: "#3ecd7f", margin: 0 }}
        position={{ bottom: 0, right: 0 }}
        alwaysShowTitle={true}
        onClick={onClick}
      >
        {options.map(({ icon, label, action }, i) => (
          <Action
            key={`fab-option-${label}-${i}`}
            text={label}
            style={{ backgroundColor: "#3498db" }}
            onClick={action}
          >
            {icon}
          </Action>
        ))}
      </Fab>
    </div>
  );
};

export default FloatingActionButton;

FloatingActionButton.defaultProps = {
  onClick: () => {},
  icon: ""
};
