import React from "react";
import { Subscribe } from "unstated";
import ComponentLoader from "../../../../../../../../Components/Loading/ComponentLoader";
import ProjectsStore from "../../../../../../Store/ProjectsStore";
import TransactionListItem from "../../../../../Transactions/Components/TransactionListItem";

const Transactions = () => {
  return (
    <Subscribe to={[ProjectsStore]}>
      {({ state: { loading, loadFailed, activeProject } }) => (
        <ComponentLoader loading={loading} loadFailed={loadFailed}>
          <div className="transactions">
            {activeProject.transactions.map(transaction => (
              <TransactionListItem
                key={`transaction-item-${transaction._id}`}
                {...transaction}
              />
            ))}
          </div>
        </ComponentLoader>
      )}
    </Subscribe>
  );
};

export default Transactions;
