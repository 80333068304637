import React, { useState } from "react";
import { Link } from "react-router-dom";
import UserDetailsForm from "App/Auth/Components/Register/Components/UserDetailsForm";
import PasswordEntryForm from "App/Auth/Components/Register/Components/PasswordEntryForm";

const Register = () => {
  const [userDetails, setUserDetails] = useState(null);

  return (
    <div className="register">
      {!userDetails ? (
        <UserDetailsForm submit={setUserDetails} />
      ) : (
        <PasswordEntryForm {...userDetails} />
      )}

      <Link to={"/auth/login"} className={"already"}>
        <p>
          Already registered? <br />
          Login here.
        </p>
      </Link>
    </div>
  );
};

export default Register;
