import Conversation from "./Conversation";
import { allConversations } from "Api/Chat";

export default class Conversations {
  constructor() {
    this.user = null;
    this.allConversations = [];
  }

  getAllConversations = async (userID, cb = () => {}) => {
    this.user = userID;

    //initialize + reset listeners

    const conversations = (await allConversations(userID)).map(conversation => {
      conversation.messages = conversation.messages.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
      return conversation;
    });
    console.log({ conversations });

    this.allConversations = conversations;

    cb(conversations);

    return conversations;
  };

  getConversation = async participantEmail => {
    console.log({ participantEmail });

    const conversation = new Conversation({
      userEmail: this.user.email,
      participantEmail
    });

    console.log({ ac: this.allConversations, participantEmail });

    let conversationID = (
      this.allConversations.find(
        ({ participant }) => participant === participantEmail
      ) || {}
    ).id;

    console.log({ conversationID });

    if (!conversationID) {
      conversationID = await conversation.createConversation(participantEmail);
    }

    await conversation.loadConversation(conversationID);

    return conversation;
  };
}
