import React, { useState } from "react";
import ProjectsStore from "../../../../../../Store/ProjectsStore";
import ComponentLoader from "../../../../../../../../Components/Loading/ComponentLoader";
import FieldworkListItem from "../../../../../Fieldwork/Components/FieldworkListItem";
import { Subscribe } from "unstated";
import CategoryFilter from "./Component/CategoryFilter";
import DateFilter from "./Component/DateFilter";
import { categories, dateFilters } from "./Data";

const Fieldwork = () => {
  const [categoryFilter, setCategoryFilter] = useState(categories[0].filter);
  const [dateFilter, setDateFilter] = useState(dateFilters[0]);

  return (
    <Subscribe to={[ProjectsStore]}>
      {({ state: { loading, loadFailed, activeProject } }) => {
        let data = activeProject.fieldwork;

        if (categoryFilter !== categories[0].filter) {
          data = data.filter(data => {
            return data.category === categoryFilter;
          });
        }
        if (dateFilters !== dateFilters[0]) {
          // data = data.filter(data => {
          //   return data.category === categoryFilter;
          // });
        }

        return (
          <ComponentLoader loading={loading} loadFailed={loadFailed}>
            <div className="fieldwork">
              <CategoryFilter
                current={categoryFilter}
                set={setCategoryFilter}
              />
              {categoryFilter === "Task" && (
                <DateFilter current={dateFilter} set={setDateFilter} />
              )}

              {data.map(fieldwork => (
                <FieldworkListItem
                  key={`fieldwork-list-item-${fieldwork._id}`}
                  {...fieldwork}
                />
              ))}
            </div>
          </ComponentLoader>
        );
      }}
    </Subscribe>
  );
};

export default Fieldwork;
