import React from "react";
import Modal from "../../../../../../../../../Components/Modal/Modal";
import AddMemberForm from "../../AddMember/Components/AddMemberForm";
import PickFromAddedContacts from "../../AddMember/Components/PickFromAddedContacts";

const ContactPickerModal = ({ arrayHelpers, hidePicker, contacts }) => {
  const addContact = ({ displayName: name, _id: id }) => {
    arrayHelpers.push({
      name,
      id
    });

    hidePicker();
  };

  return (
    <Modal close={hidePicker}>
      <div className="contactPickerModal">
        <AddMemberForm setUser={addContact} />
        <PickFromAddedContacts
          setUser={addContact}
          filterAddedContacts={contacts.map(({ id }) => id)}
        />
      </div>
    </Modal>
  );
};

export default ContactPickerModal;
