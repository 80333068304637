import { Container } from "unstated";
import { messageSchema } from "Modules/Messaging/DBSchema/Message";
import Conversations from "Modules/Messaging/DBSchema/Conversations";

const initialState = () => ({
  loading: true,
  loadFailed: false,
  messages: Array.from({ length: 10 }).map((_, i) =>
    messageSchema(
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt, voluptate.",
      i % 2 ? "qwe@qwe.qwe" : "asd@asd.asd"
    )
  ),
  conversations: [],
  meta: {},
  activeConversation: null
});

export default class MessagingStore extends Container {
  name = "MessagingStore";

  state = initialState();

  linkedStores = {};

  init = async () => {
    console.log("initializing MessagingStore");
    await this.setState({ ...initialState() });
    const userId = this.linkedStores.UserStore.state.id;

    const conversations = new Conversations();

    await conversations.getAllConversations(userId, conversations =>
      this.setState({ conversations })
    );

    await this.setState({
      loading: false,
      activeConversation: null
    });
  };

  sendMessage = async message => {
    const msg = message.trim();

    if (!msg) {
      return;
    }

    console.log({ message });

    const { activeConversation } = this.state;

    if (!activeConversation) {
      return;
    }

    await activeConversation.sendMessage(msg);
  };

  setActiveConversation = async conversationID => {
    const { conversations } = this.state;

    const activeConversation = conversations.find(
      ({ conversation: { _id } }) => _id === conversationID
    );

    await this.setState({ activeConversation });
  };

  bindStore = store => {
    this.linkedStores[store.name] = store;
  };
}
