import React from "react";
import { Field, Form, withFormik } from "formik";
import withStore from "../../../../../../../../../Components/Unstated/withStore";
import Input from "../../../../../../../../../Components/Input/Input";
import ProjectsStore from "../../../../../../../Store/ProjectsStore";
import Button from "../../../../../../../../../Components/Buttons/Button";

const AddMemberForm = ({ isSubmitting }) => {
  return (
    <div className="addMemberForm">
      <h1>Add Member</h1>
      <Form>
        <Field
          render={props => (
            <Input
              {...props}
              type={"phone"}
              placeholder={"Phone Number"}
              countryCodeField={"countryCode"}
            />
          )}
          name={"number"}
        />
        <Field
          name={"name"}
          render={props => <Input {...props} placeholder={"Display Name"} />}
        />
        <Button
          onClick={() => {}}
          name={"Add"}
          type={"primary"}
          buttonType={"submit"}
          disabled={isSubmitting}
          loading={isSubmitting}
        />
      </Form>
    </div>
  );
};

export default withStore([ProjectsStore])(
  withFormik({
    mapPropsToValues: () => ({
      name: "",
      number: "",
      countryCode: "91"
    }),
    handleSubmit: async (
      values,
      {
        props: {
          projectsStore: { getUser },
          setUser
        },
        setSubmitting
      }
    ) => {
      console.log({ values });
      const user = await getUser(values);
      if (user) {
        setUser(user);
      }
      setSubmitting(false);
    }
  })(AddMemberForm)
);
