import React from "react";
import { Subscribe } from "unstated";
import ComponentLoader from "../../../../Components/Loading/ComponentLoader";
import ProjectsStore from "../../Store/ProjectsStore";
import FieldworkListItem from "./Components/FieldworkListItem";

const Fieldwork = () => {
  return (
    <Subscribe to={[ProjectsStore]}>
      {({ state: { loading, loadFailed, fieldwork } }) => (
        <ComponentLoader loading={loading} loadFailed={loadFailed}>
          <div className="fieldwork">
            {fieldwork.map(fieldwork => (
              <FieldworkListItem
                key={`fieldwork-list-item-${fieldwork._id}`}
                {...fieldwork}
              />
            ))}
          </div>
        </ComponentLoader>
      )}
    </Subscribe>
  );
};

export default Fieldwork;
