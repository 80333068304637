import React, { useEffect } from "react";
import { Switch, withRouter } from "react-router-dom";
import "./Projects.sass";
import Route from "../../Components/Router/Route";
import { default as ProjectsSection } from "./Components/Projects";
import Fieldwork from "./Components/Fieldwork";
import Transactions from "./Components/Transactions";
import withStore from "../../Components/Unstated/withStore";
import ProjectsStore from "./Store/ProjectsStore";
import Tabs from "./Components/Tabs";
import CreateProjectButton from "./Components/CreateProjectButton";
import CreateProject from "./Components/Create";

const Projects = ({ projectsStore: { init }, match: { url } }) => {
  useEffect(() => {
    init();
  }, [init]);

  return (
    <div className="projects">
      <Tabs />
      <Switch>
        <Route path={`${url}/transactions`} render={() => <Transactions />} />
        <Route path={`${url}/fieldwork`} render={() => <Fieldwork />} />
        <Route path={`${url}/create`} render={() => <CreateProject />} />
        <Route path={`${url}`} render={() => <ProjectsSection />} />
      </Switch>
      <CreateProjectButton />
    </div>
  );
};

export default withStore([ProjectsStore])(withRouter(Projects));
