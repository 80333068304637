import React from "react";
import { Field, Form, withFormik } from "formik";
import * as Yup from "yup";
import withStore from "Components/Unstated/withStore";
import UserStore from "App/Auth/Store/UserStore";
import Input from "Components/Input/Input";
import {
  email,
  isRequired,
  string
} from "Helpers/Form Validations/Validation Error Messages";
import Button from "Components/Buttons/Button";

const LoginForm = ({ isSubmitting }) => {
  return (
    <div className="loginForm">
      <div className="info">
        <h1>Sign in to continue</h1>
      </div>
      <Form>
        <Field
          name={"email"}
          render={props => <Input {...props} placeholder={"Email"} />}
        />
        <Field
          name={"password"}
          render={props => (
            <Input {...props} placeholder={"Password"} type={"password"} />
          )}
        />
        <Button
          onClick={() => {}}
          name={"Login"}
          type={"primary"}
          loading={isSubmitting}
          disabled={isSubmitting}
          buttonType={"submit"}
        />
      </Form>
    </div>
  );
};

export default withStore([UserStore])(
  withFormik({
    mapPropsToValues: () => ({
      email: "gaurav15parmar@gmail.com",
      password: "superman"
    }),
    validationSchema: () =>
      Yup.object().shape({
        email: Yup.string(string)
          .email(email)
          .required(isRequired("Email-ID")),
        password: Yup.string(string).required(isRequired("Password"))
      }),
    handleSubmit: async (values, { props }) => {
      await props.userStore.passwordAuth(values);
    }
  })(LoginForm)
);
