import React from "react";
import { Field } from "formik";
import Input from "../../../../../../../../../Components/Input/Input";

const ContactList = ({ contacts, arrayHelpers }) => {
  return (
    <div className="contactList">
      {contacts &&
        contacts.map((item, i) => (
          <div key={"contacts-" + i} className="contactArrayUnit">
            <div className="content">
              <Field
                name={`contacts.${i}.name`}
                render={props => (
                  <Input {...props} placeholder="Contact Name" disabled />
                )}
              />
            </div>
            <div className="actions">
              <div className="delete" onClick={() => arrayHelpers.remove(i)} />
            </div>
          </div>
        ))}
    </div>
  );
};

export default ContactList;
