import React, { useEffect } from "react";
import { Redirect, Switch, withRouter } from "react-router-dom";
import withStore from "../../Components/Unstated/withStore";
import MessagingStore from "./Stores/MessagingStore";
import ChatInterface from "Modules/Messaging/Components/ChatInterface";
import ConversationsList from "Modules/Messaging/Components/ConversationsList";
import ComponentLoader from "Components/Loading/ComponentLoader";
import Route from "Components/Router/Route";

const Messaging = ({
  messagingStore: {
    state: { loading, loadFailed },
    init
  },
  match: { url }
}) => {
  useEffect(() => {
    init();
  }, [init]);

  return (
    <ComponentLoader loading={loading} loadFailed={loadFailed}>
      <Switch>
        <Route exact path={`${url}`} render={() => <ConversationsList />} />
        <Route path={`${url}/:id`} render={() => <ChatInterface />} />
        <Route render={() => <Redirect to={url} />} />
      </Switch>
    </ComponentLoader>
  );
};

export default withStore([MessagingStore])(withRouter(Messaging));
