export const paymentConfig = ({
  name,
  description,
  amount,
  currency,
  prefill = {},
  orderId,
  handler
}) => ({
  description,
  image: "https://unsplash.it/600",
  currency,
  key: process.env.REACT_APP_RAZORPAY_KEY,
  amount,
  name,
  prefill,
  order_id: orderId,
  handler,
  theme: { color: "#3ecd7f" }
});
