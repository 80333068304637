import axios from "axios";
import { auth, database, initializeApp } from "firebase";
import { getField } from "../../Helpers/Misc";

export let Api = null;
export let firebase = {
  database,
  auth
};

export const initApis = () => {
  Api = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  initializeApp({
    apiKey: "AIzaSyDrLKkpH8Hie1IooAbKo5n2v6Kz2V7xz-g",
    authDomain: "dev-contractflo.firebaseapp.com",
    databaseURL: "https://dev-contractflo.firebaseio.com",
    projectId: "dev-contractflo",
    storageBucket: "dev-contractflo.appspot.com",
    messagingSenderId: "671558970982",
    appId: "1:671558970982:web:b6c60a6268862feefa47a7"
  });

  //todo: setup interceptors

  Api.interceptors.request.use(
    function(config) {
      const user = window.sessionStorage.getItem("user");
      const uid = window.sessionStorage.getItem("uid");
      config.data = {
        ...(config.data || {}),
        ...(user && !getField(config, "data.user") ? { user } : {}),
        ...(uid && !getField(config, "data.uid") ? { uid } : {})
      };
      return config;
    },
    error => Promise.reject(error)
  );
  Api.interceptors.response.use(
    response => response.data,
    error => {
      console.log({ error });
      return false;
    }
  );
};
