import { Api } from "./Configuration";
import { milestoneCalculations } from "../App/Explore/Helpers";

export const getAllInvestments = () =>
  Api.post("investment/getPublishedProjects/").then(investments =>
    investments.map(investment => ({
      ...investment,
      milestones: milestoneCalculations(investment.milestones)
    }))
  );

export const getPaymentOrderID = order =>
  Api.post(`razorpay/getRazorpayOrder/`, { razorpayOrderId: order }).then(
    ({ razorpayId }) => razorpayId
  );
