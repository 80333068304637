import React from "react";
import { Subscribe } from "unstated";
import InvestmentStore from "../../Stores/InvestmentStore";
import InvestmentElement from "./Components/InvestmentElement";

const InvestmentsList = () => {
  return (
    <Subscribe to={[InvestmentStore]}>
      {({ state: { investments } }) => (
        <div className="investmentsList">
          {investments.map((investment, index) => (
            <InvestmentElement
              key={`investment-element-${index}`}
              {...investment}
            />
          ))}
        </div>
      )}
    </Subscribe>
  );
};

export default InvestmentsList;
