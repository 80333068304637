import React from "react";
import { Subscribe } from "unstated";
import ProjectsStore from "../../../../../../../Store/ProjectsStore";

const Members = () => {
  return (
    <Subscribe to={[ProjectsStore]}>
      {({ state: { activeProject } }) => {
        return (
          <div className="members">
            <div className="header">
              <div className="icon" >
                <span className='memberIcon'/>
              </div>
              <p>Members</p>
            </div>
            <div className="list">
              {activeProject.participants.map(
                (
                  {
                    displayName,
                    phone,
                    displayPicture: { thumbUrl: image },
                    _id
                  },
                  i
                ) => (
                  <div className="member" key={`members-list-item-${i}`}>
                    <div
                      className="icon"
                      style={{
                        background: `url(${image}) center center /cover no-repeat`
                      }}
                    />
                    <div className="info">
                      <p className="name">{displayName}</p>
                      <p className="contact">{phone}</p>
                    </div>
                    <div className="role">
                      <p>
                        {activeProject.managers.find(id => id === _id)
                          ? "Manager"
                          : "Professional"}
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        );
      }}
    </Subscribe>
  );
};

export default Members;
