import React from "react";
import TransactionListItem from "App/Projects/Components/Transactions/Components/TransactionListItem";
import { Subscribe } from "unstated";
import ProjectsStore from "App/Projects/Store/ProjectsStore";
import ComponentLoader from "Components/Loading/ComponentLoader";

const Transactions = () => {
  return (
    <Subscribe to={[ProjectsStore]}>
      {({ state: { loading, loadFailed, transactions } }) => (
        <ComponentLoader loading={loading} loadFailed={loadFailed}>
          <div className="transactions">
            {transactions.map(transaction => (
              <TransactionListItem
                key={`transaction-item-${transaction._id}`}
                {...transaction}
              />
            ))}
          </div>
        </ComponentLoader>
      )}
    </Subscribe>
  );
};

export default Transactions;
