import {Container} from "unstated";
import {setMobileFullHeightVariable} from "Helpers/Interface/MobileFullHeight";
import {Api, initApis, firebase} from "Api/Configuration";

const initialState = () => ({
  loading: true,
  loadFailed: false
});

export default class AppStore extends Container {
  name = "AppStore";

  state = initialState();

  linkedStores = {};

  init = async () => {
    console.log("initializing AppStore");
    await this.setState(initialState());

    setMobileFullHeightVariable();
    initApis();

    const operations = await Promise.all([this.linkedStores.UserStore.init()]);

    const success = operations.every(operation => !!operation);

    window.api = Api;
    window.firebase = firebase;

    await this.setState({loading: false, loadFailed: !success});
  };

  bindStore = store => {
    this.linkedStores[store.name] = store;
  };
}
