import React, { useEffect, useRef, useState } from "react";
import MessageElement from "./MessageElement";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List
} from "react-virtualized";
import ComponentLoader from "Components/Loading/ComponentLoader";
import withStore from "Components/Unstated/withStore";
import { disableParentTouchScroll } from "Modules/Messaging/Helpers";
import MessagingStore from "Modules/Messaging/Stores/MessagingStore";
import UserStore from "App/Auth/Store/UserStore";

const MessageList = ({
  messagingStore: {
    state: { loading, loadFailed, messages, activeConversation }
  },
  userStore: {
    state: { id: userID }
  }
}) => {
  const listEl = useRef(null);
  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100
    })
  );

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded && listEl.current) {
      listEl.current.scrollToRow(messages.length);
    }
  }, [loaded, messages.length]);

  useEffect(() => {
    const scrollToBottom = () => {
      window.requestAnimationFrame(() => {
        listEl.current.scrollToRow(messages.length);
      });
    };

    window.addEventListener("resize", scrollToBottom);

    return () => window.removeEventListener("resize", scrollToBottom);
  }, [messages]);

  return (
    <div className="messageList" {...disableParentTouchScroll}>
      <ComponentLoader loading={loading} loadFailed={loadFailed}>
        <AutoSizer>
          {({ width, height }) => {
            return (
              <List
                ref={listEl}
                width={width}
                height={height}
                deferredMeasurementCache={cache.current}
                onRowsRendered={() => setLoaded(true)}
                rowHeight={cache.current.rowHeight}
                rowRenderer={({ index, key, style, parent }) => {
                  const message = activeConversation.messages[index];
                  return (
                    <CellMeasurer
                      key={message._id}
                      cache={cache.current}
                      parent={parent}
                      columnIndex={0}
                      rowIndex={index}
                    >
                      <MessageElement
                        style={style}
                        createdAt={message.createdAt}
                        text={message.text}
                        received={userID !== message.user._id}
                      />
                    </CellMeasurer>
                  );
                }}
                rowCount={activeConversation.messages.length}
                overscanRowCount={3}
              />
            );
          }}
        </AutoSizer>
      </ComponentLoader>
    </div>
  );
};

export default withStore([MessagingStore, UserStore])(MessageList);
