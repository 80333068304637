import React from "react";
import "./Chat.sass";
import Messaging from "Modules/Messaging";

const Chat = () => {
  return (
    <div className="chat">
      <Messaging />
    </div>
  );
};

export default Chat;
