import React from "react";
import "./Confirmation.sass";
import Button from "../Buttons/Button";
import {Portal} from "react-portal";

const Confirmation = ({question, promise}) => {
  return (
    <Portal>
      <div className="confirmation">
        <div className="modal">
          <p className="message">{question || `Are you sure?`}</p>
          <div className="actions">
            <Button
              onClick={() => {
                promise.reject();
              }}
              name={"No"}
              type={"secondary"}
            />
            <Button
              onClick={() => {
                promise.resolve();
              }}
              name={"Yes"}
              type={"primary"}
            />
          </div>
        </div>
      </div>
    </Portal>
  );
};

Confirmation.propTypes = {};

export default Confirmation;
