import React from "react";
import "./CreateFieldwork.sass";
import { Field, FieldArray, Form, withFormik } from "formik";
import { withRouter } from "react-router-dom";
import Input from "../../../../../../../../Components/Input/Input";
import { generateOptions } from "../../../../../../../../Helpers/Misc";
import { fieldworkCategories, fieldworkStatuses } from "./Data";
import * as Yup from "yup";
import {
  isRequired,
  string
} from "../../../../../../../../Helpers/Form Validations/Validation Error Messages";
import Button from "../../../../../../../../Components/Buttons/Button";
import ContactPicker from "../CreateTransaction/Components/ContactPicker";
import { parseValues } from "./Helpers";
import withStore from "../../../../../../../../Components/Unstated/withStore";
import ProjectsStore from "../../../../../../Store/ProjectsStore";

const CreateFieldwork = ({ isSubmitting, handleSubmit, values }) => {
  return (
    <div className="createFieldwork">
      <h1>Create Fieldwork</h1>
      <Form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Field
          name="category"
          render={props => (
            <Input
              {...props}
              type="select"
              options={generateOptions(fieldworkCategories)}
              isSearchable={false}
              placeholder={"Category"}
            />
          )}
        />
        <Field
          name={`description`}
          render={props => (
            <Input {...props} placeholder="Description" type={"textarea"} />
          )}
        />
        {values.category === "Task" && (
          <>
            <Field
              name="status"
              render={props => (
                <Input
                  {...props}
                  type="select"
                  options={generateOptions(fieldworkStatuses, "name", "name")}
                  isSearchable={false}
                  placeholder={"Status"}
                />
              )}
            />
            {!!values.contacts.length && <h2>Contacts</h2>}
            <FieldArray
              name="contacts"
              render={arrayHelpers => (
                <ContactPicker arrayHelpers={arrayHelpers} values={values} />
              )}
            />
          </>
        )}

        <Button
          onClick={() => handleSubmit()}
          name={"Submit"}
          type={"primary"}
          buttonType={"submit"}
          disabled={isSubmitting}
          loading={isSubmitting}
        />
      </Form>
    </div>
  );
};

export default withRouter(
  withStore([ProjectsStore])(
    withFormik({
      mapPropsToValues: () => ({
        category: fieldworkCategories[0],
        status: fieldworkStatuses[0].name,
        description: "",
        contacts: []
      }),
      validationSchema: () =>
        Yup.object().shape({
          category: Yup.string(string).required(isRequired("Category")),
          status: Yup.string(string).required(isRequired("Status")),
          description: Yup.string(string).required(isRequired("Description")),
          contacts: Yup.array().of(
            Yup.object().shape({
              name: Yup.string(string).required(isRequired("Name")),
              id: Yup.string(string).required(isRequired("ID"))
            })
          )
        }),
      handleSubmit: async (
        values,
        {
          props: {
            projectsStore: { createFieldwork },
            history,
            location: { pathname }
          },
          setSubmitting
        }
      ) => {
        const success = await createFieldwork({
          ...parseValues(values),
          users: values.contacts.map(({ id }) => id)
        });
        if (success) {
          history.push(
            pathname
              .split("/")
              .slice(0, -1)
              .join("/") + "/fieldwork"
          );
        } else {
          setSubmitting(false);
        }
      }
    })(CreateFieldwork)
  )
);
