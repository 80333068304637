import React from "react";
import { withRouter } from "react-router-dom";
import { Field, Form, withFormik } from "formik";
import withStore from "../../../../../Components/Unstated/withStore";
import ProjectsStore from "../../../Store/ProjectsStore";
import Input from "../../../../../Components/Input/Input";
import Button from "../../../../../Components/Buttons/Button";
import * as Yup from "yup";
import {
  isRequired,
  string
} from "../../../../../Helpers/Form Validations/Validation Error Messages";

const CreateForm = ({ isSubmitting }) => {
  return (
    <div className="createForm">
      <Form>
        <Field
          name={"title"}
          render={props => <Input {...props} placeholder={"Project Title"} />}
        />
        <Field
          name={"address"}
          render={props => (
            <Input
              {...props}
              placeholder={"Project Address"}
              type={"textarea"}
            />
          )}
        />
        <Button
          onClick={() => {}}
          name={"Initialize Project"}
          type={"primary"}
          buttonType={"submit"}
          loading={isSubmitting}
          disabled={isSubmitting}
        />
      </Form>
    </div>
  );
};

export default withRouter(
  withStore([ProjectsStore])(
    withFormik({
      mapPropsToValues: () => {
        return {
          title: "",
          address: ""
        };
      },
      validationSchema: () =>
        Yup.object().shape({
          title: Yup.string(string).required(isRequired("Project Title")),
          address: Yup.string(string).required(isRequired("Project Address"))
        }),
      handleSubmit: async (
        values,
        {
          props: {
            projectsStore: { createProject },
            history
          },
          setSubmitting
        }
      ) => {
        const { title, address } = values;
        const success = await createProject({
          title,
          address: address.split("\n").join(", ")
        });
        if (success) {
          history.push(`/projects/${success._id}`);
        }
        setSubmitting(false);
      }
    })(CreateForm)
  )
);
