import React from "react";
import { Link } from "react-router-dom";
import { Subscribe } from "unstated";
import ProjectsStore from "../../../../../Store/ProjectsStore";

const ProjectHeader = () => {
  return (
    <Subscribe to={[ProjectsStore]}>
      {({ state: { activeProject } }) => {
        return (
          <div className="projectHeader">
            <Link to={"/projects"}>
              <div className="back" />
            </Link>
            <h1>{activeProject.title}</h1>
          </div>
        );
      }}
    </Subscribe>
  );
};

export default ProjectHeader;
