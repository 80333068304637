import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ScriptLoader from "react-script-loader-hoc";
import "./Payment.sass";
import Loading from "../../Components/Loading/Loading";
import { getParamsFromUrl } from "../../Helpers/Misc";
import { getPaymentOrderID } from "../../Api/Investment";
import { paymentConfig } from "./Config";
import withStore from "../../Components/Unstated/withStore";
import PaymentStore from "./Stores/PaymentStore";

const Payment = ({
  location: { pathname, search },
  paymentStore: { processPayment }
}) => {
  const [orderId, setId] = useState("");

  useEffect(() => {
    (async () => {
      const [
        orderId
      ] = getParamsFromUrl(`${window.location.origin}/${pathname}/${search}`, [
        "orderId"
      ]);

      const paymentID = await getPaymentOrderID(orderId);
      console.log({ paymentID });

      setId(paymentID);
    })();
  }, [pathname, search]);

  useEffect(() => {
    if (orderId) {
      // eslint-disable-next-line no-undef
      const rzp = new Razorpay(
        paymentConfig({
          name: `ContractFlo`,
          orderId,
          handler: processPayment
        })
      );
      rzp.open();
    }
  }, [processPayment, orderId]);

  return (
    <div className="payment">
      <Loading />
    </div>
  );
};

export default ScriptLoader("https://checkout.razorpay.com/v1/checkout.js")(
  withStore([PaymentStore])(withRouter(Payment))
);
