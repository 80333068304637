import React from "react";
import { Subscribe } from "unstated";
import InvestmentStore from "../../../Stores/InvestmentStore";

const InvestmentDescription = () => {
  return (
    <Subscribe to={[InvestmentStore]}>
      {({
        state: {
          activeInvestment: { description }
        }
      }) => (
        <div className="investmentDescription">
          <h1>Description</h1>
          <p>{description}</p>
        </div>
      )}
    </Subscribe>
  );
};

export default InvestmentDescription;
