const millisInDay = 24 * 60 * 60 * 1000;

export const milestoneDurations = milestones => {
  const newMilestones = [];

  milestones.forEach((milestone, index) => {
    const { status, started, completed, expectedDuration } = milestone;
    const previous = !!index && newMilestones[index - 1];

    if (status === 0) {
      if (!index) {
        newMilestones.push({
          ...milestone,
          started: new Date().getTime(),
          completed: started + Number(expectedDuration) * millisInDay
        });
      } else {
        newMilestones.push({
          ...milestone,
          started: previous.completed,
          completed: previous.completed + Number(expectedDuration) * millisInDay
        });
      }
    } else if (status === 1) {
      newMilestones.push({
        ...milestone,
        started: new Date(started).getTime(),
        completed:
          new Date(started).getTime() + Number(expectedDuration) * millisInDay
      });
    } else {
      newMilestones.push({
        ...milestone,
        started: new Date(started).getTime(),
        completed: new Date(completed).getTime()
      });
    }
  });

  return newMilestones;
};

export const milestoneCalculations = milestonesRaw => {
  const milestones = milestoneDurations(milestonesRaw);
  const [first, last] = [milestones[0], milestones.slice(-1)[0]];
  const [started, completed] = [first.started, last.completed];
  const durationTotal = completed - started;
  const durationNow = new Date().getTime() - started;
  const percentage = (durationNow / durationTotal) * 100;
  let cost = 0;
  let milestonesWithCost = milestones.map(milestone => {
    cost += milestone.status === 2 ? milestone.cost : milestone.expectedCost;
    return {
      ...milestone,
      aggregateCost: cost
    };
  });

  return {
    milestonesWithCost,
    totalCost: cost,
    percentage,
    durationTotal,
    durationNow,
    started,
    completed
  };
};

export const oneTimeReturns = (amount, growthRatePA, timeInMonths) => {
  let multiplier = Math.pow(growthRatePA, timeInMonths / 12);
  return amount * multiplier;
};

export const fixedSIP = (amount, growthRatePA, timeInMonth, step) => {
  let sipAmount = 0;
  for (let i = timeInMonth; i > 0; i = i - step) {
    sipAmount = sipAmount + oneTimeReturns(amount, growthRatePA, i);
  }
  return sipAmount;
};
