import { messageSchema } from "./Message";
import { firebase } from "Api/Configuration";

const uniqid = require("uniqid");

const { database } = firebase;

const schema = ({ userID, participantID, id = uniqid() }) => ({
  participants: [userID, participantID],
  messages: [],
  createdAt: new Date().getTime(),
  id: id
});

export default class Conversation {
  constructor({ userID, participantID }) {
    this.userID = userID;
    this.participantID = participantID;
    this.userDoc = database.collection("users").doc(userID);
    this.participantDoc = database.collection("users").doc(participantID);
    this.conversationsCollection = database.collection("conversations");
    this.activeConversation = null;
  }

  createConversation = async () => {
    console.log({ cc: this.conversationsCollection });

    const conversation = schema({
      userID: this.userID,
      participantID: this.participantID
    });

    console.log({ conversation });

    const id = (await this.conversationsCollection.add(conversation)).id;

    await this.userDoc.update({
      conversations: firebase.firestore.FieldValue.arrayUnion({
        id,
        participant: this.participantID
      })
    });

    await this.participantDoc.update({
      conversations: firebase.firestore.FieldValue.arrayUnion({
        id,
        participant: this.userID
      })
    });

    return id;
  };

  loadConversation = async conversationID => {
    if (!conversationID) {
      throw Error("Conversation ID is required to load a conversation");
    }

    this.activeConversation = this.conversationsCollection.doc(conversationID);

    return this.activeConversation.get();
  };

  sendMessage = text => {
    if (!this.activeConversation) {
      throw Error("A conversation must be loaded for sending a message");
    }

    this.activeConversation.update({
      messages: firebase.firestore.FieldValue.arrayUnion(
        messageSchema(text, this.userID)
      )
    });
  };

  subscribeToMessages = cb =>
    this.activeConversation.onSnapshot(doc => cb(doc.data().messages));
}
