import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Button.sass";
import Loading from "../Loading/Loading";
import { generateConfirmation } from "../Confirmation/Helpers";
import Confirmation from "../Confirmation/Confirmation";

const Button = ({
  name,
  onClick,
  disabled,
  loading,
  type,
  className,
  buttonType,
  icon,
  iconSrc,
  withConfirmation,
  ...props
}) => {
  const [confirmation, setConfirmation] = useState(false);

  return (
    <button
      onClick={e => {
        if (!withConfirmation) {
          onClick(e);
        } else {
          const confirmation = generateConfirmation(
            () => {
              onClick(e);
              setConfirmation(null);
            },
            () => {
              setConfirmation(null);
            }
          );

          setConfirmation(confirmation);
        }
      }}
      disabled={disabled}
      className={
        type + " defaultButton " + className + (disabled ? " disabled" : "")
      }
      type={buttonType}
      {...props}
    >
      {confirmation && <Confirmation promise={confirmation} />}
      {loading ? (
        <Loading color={type.includes("secondary") && "white"} small />
      ) : (
        <>
          {(type === "icon" || type === "withIcon") && (
            <span
              className={icon}
              style={
                iconSrc
                  ? {
                      background: `url(${iconSrc}) center center /contain no-repeat`
                    }
                  : {}
              }
            />
          )}
          {name}
        </>
      )}
    </button>
  );
};

Button.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconSrc: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(["primary", "secondary", "icon", "withIcon", "text"])
    .isRequired
};

export default Button;
