import React from "react";
import Radio from "./Components/Radio";
import TextArea from "./Components/Textarea";
import "./Input.sass";
import Dropdown from "./Components/Dropdown";
import Password from "./Components/Password";
import Toggle from "./Components/Toggle";
import SimpleInput from "./Components/SimpleInput";
import DatePicker from "./Components/DatePicker";
import PhoneNumber from "./Components/PhoneNumber";

const Input = props => {
  const { type } = props;

  return type === "radio" ? (
    <Radio {...props} />
  ) : type === "textarea" ? (
    <TextArea {...props} />
  ) : type === "select" ? (
    <Dropdown {...props} />
  ) : type === "password" ? (
    <Password {...props} />
  ) : type === "phone" ? (
    <PhoneNumber {...props} />
  ) : type === "switch" ? (
    <Toggle {...props} />
  ) : type === "calendar" ? (
    <DatePicker {...props} />
  ) : (
    <SimpleInput {...props} />
  );
};

export default Input;
