import React, { useEffect, useState } from "react";
import { default as DatePickerModule } from "react-date-picker";

const DatePicker = ({ placeholder, field = {}, form = {}, ...props }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(field.value || props.value);
  }, [field.value, props.value]);

  const onChange = date => {
    setValue(date);
    form.setFieldValue && form.setFieldValue(field.name, date);
    form.setFieldTouched && form.setFieldTouched(field.name, true);
    return props.onChange
      ? props.onChange(date || "")
      : field.onChange(date || "");
  };

  return (
    <div className="inputWrapper datePicker">
      <p className={"placeholder " + (value ? " active" : "")}>{placeholder}</p>
      <DatePickerModule
        {...props}
        {...field}
        onChange={onChange}
        value={value}
        clearIcon={null}
      />
      {form.touched[field.name] && (
        <p className="error">{form.errors[field.name]}</p>
      )}
    </div>
  );
};

export default DatePicker;
