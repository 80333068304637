import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ToastNotifications.sass";

const ToastNotification = () => {
  return (
    <div className="toastNotification">
      <ToastContainer />
    </div>
  );
};

export default ToastNotification;
