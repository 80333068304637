export const blacklistedRoutes = [
  {
    route: "/",
    exact: true
  },
  {
    route: "/auth",
    exact: false
  }
];
