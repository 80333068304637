import React, { useEffect, useState } from "react";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { getCountryByIP } from "Api/UserAuth";

const PhoneNumber = ({
  field = {},
  form = {},
  placeholder,
  value,
  countryCodeField,
  ...props
}) => {
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (!valid && !form.errors[field.name]) {
      form.setFieldError(field.name, "Please enter the correct phone number");
    }
  }, [field.name, form, valid]);

  return (
    <div className="inputWrapper phone">
      <IntlTelInput
        {...props}
        containerClassName="intl-tel-input"
        inputClassName="form-control"
        defaultCountry="auto"
        geoIpLookup={async cb => cb(await getCountryByIP())}
        customPlaceholder={selectedCountryPlaceholder =>
          "e.g. " + selectedCountryPlaceholder
        }
        onPhoneNumberChange={(valid, number, country, ...props) => {
          setValid(valid);
          props.onChange
            ? props.onChange(number)
            : form.setFieldValue(field.name, number);
          countryCodeField &&
            form.setFieldValue(countryCodeField, country.dialCode);
        }}
        onPhoneNumberBlur={() => {
          form.setFieldTouched(field.name, true);
        }}
        format={true}
        value={field.value || value}
      />
      <p className={"placeholder active"}>{placeholder}</p>
      <p className={"error"}>
        {form.errors && form.touched[field.name] && form.errors[field.name]}
      </p>
    </div>
  );
};

export default PhoneNumber;
