import React from "react";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";

export const notify = (
  type = "information",
  message = "",
  interaction = () => {}
) =>
  toast(
    () => (
      <div className={"toast " + type}>
        <span className={"icon " + type} />
        <div className="info">
          <h1 className={"header " + type}>
            {type === "success"
              ? "Success"
              : type === "information"
              ? "Info"
              : type === "error"
              ? "Error"
              : ""}
          </h1>
          <p className="text" onClick={interaction}>
            {message}
          </p>
        </div>
      </div>
    ),
    {
      autoClose: 3000,
      closeButton: false,
      hideProgressBar: true,
      className: type,
      ...(isMobile
        ? {
            position: "top-center"
          }
        : {})
    }
  );

export const parseNotificationText = (text, data) => {
  let result = text;
  if (data && text) {
    Object.keys(data).forEach(key => {
      let value = data[key];
      if (key.toLowerCase().includes("time")) {
        value =
          new Date(data[key]).toLocaleTimeString() +
          ", " +
          new Date(data[key]).toDateString();
      }
      result = result.replace(`@${key}`, value);
    });
  }
  return result;
};

window.notify = notify;
