export const bottomNavLinks = [
  {
    name: "Explore",
    route: "/explore"
  },
  {
    name: "Projects",
    route: "/projects"
  },
  {
    name: "Chat",
    route: "/chat"
  }
];
