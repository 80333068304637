import React from "react";
import { Link } from "react-router-dom";
import { Subscribe } from "unstated";
import InvestmentStore from "../../../Stores/InvestmentStore";

const InvestmentHeader = () => {
  return (
    <Subscribe to={[InvestmentStore]}>
      {({ state: { activeInvestment } }) => {
        return (
          <div className="investmentHeader">
            <Link to={"/explore"}>
              <div className="back" />
            </Link>
            <h1>{activeInvestment.project.title}</h1>
          </div>
        );
      }}
    </Subscribe>
  );
};

export default InvestmentHeader;
