import React from "react";
import LoginForm from "App/Auth/Components/Login/Components/LoginForm";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <div className="login">
      <LoginForm />
      <Link to={"/auth/register"} className={"already"}>
        <p>
          New User? <br />
          Register Here.
        </p>
      </Link>
    </div>
  );
};

export default Login;
