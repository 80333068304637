import React from "react";
import { Link, withRouter } from "react-router-dom";
import { tabs } from "App/Projects/Data/tabs";

const Tabs = ({ match: { url }, location: { pathname } }) => {
  return (
    <div className="tabs">
      {tabs.map(({ route, name }, i) => (
        <Link to={url + route} key={`project-tab=el--${i}`}>
          <div
            className={"tabEl" + (pathname === url + route ? " active" : "")}
          >
            <div className="icon">
              <span className={name.toLowerCase() + "-icon"} />
            </div>
            <div className="name">{name}</div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default withRouter(Tabs);
