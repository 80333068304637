export const transactionTypes = [
  "Architectural & Structural",
  "Contractors",
  "Construction Materials",
  "Interior Work",
  "Machinery",
  "Permits & Approvals"
];

export const transactionCurrencies = [
  {
    symbol: "₹",
    name: "INR"
  },
  {
    symbol: "$",
    name: "USD"
  }
];
