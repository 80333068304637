export let vhm = window.innerHeight * 0.01;

export const setMobileFullHeightVariable = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  document.documentElement.style.setProperty("--vhc", `${vh}px`);
  window.addEventListener("resize", () => {
    // We execute the same script as before
    vhm = Math.max(vhm, window.innerHeight * 0.01);
    let vh = vhm;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    document.documentElement.style.setProperty(
      "--vhc",
      `${window.innerHeight * 0.01}px`
    );
  });
};
