import React, { useState } from "react";
import AddMemberForm from "./Components/AddMemberForm";
import PickFromAddedContacts from "./Components/PickFromAddedContacts";
import MemberRolePicker from "./Components/MemberRolePicker";

const AddMember = () => {
  const [user, setUser] = useState(null);

  return (
    <div className="addMember">
      {!user ? (
        <>
          <AddMemberForm setUser={setUser} />
          <PickFromAddedContacts setUser={setUser} />
        </>
      ) : (
        <MemberRolePicker user={user} back={() => setUser(null)} />
      )}
    </div>
  );
};

export default AddMember;
