import React, { useState } from "react";
import { getField } from "../../../Helpers/Misc";

const SimpleInput = ({
  placeholder,
  field = {},
  form = {},
  options,
  type,
  editState,
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  const onChange = e => {
    form.setFieldValue && form.setFieldValue(field.name, e.target.value);
    return props.onChange ? props.onChange(e) : field.onChange(e);
  };

  let className =
    loading && editState
      ? "loading"
      : !loading && editState && form.touched[field.name]
      ? "saved"
      : props.className;

  return (
    <div className="inputWrapper">
      <input
        type={type || "text"}
        placeholder=" "
        minLength={props.minLength}
        maxLength={props.limit}
        {...props}
        {...field}
        onChange={async e => {
          setLoading(true);
          await onChange(e);
          setLoading(false);
        }}
        className={className}
      />
      <p className={"placeholder"}>{placeholder}</p>
      <p className={"error"}>
        {form.errors &&
          getField(form.touched, field.name) &&
          getField(form.errors, field.name)}
      </p>
    </div>
  );
};

export default SimpleInput;
