import React from "react";
import "./TopNav.sass";

const TopNav = () => {
  return (
    <div className="topNav">
      <img src="https://unsplash.it/500" alt="Logo" className="logo" />
      <p>ContractFlo</p>
    </div>
  );
};

export default TopNav;
