import React from "react";
import { Field, Form, withFormik } from "formik";
import * as Yup from "yup";
import {
  email,
  isRequired,
  string
} from "Helpers/Form Validations/Validation Error Messages";
import Input from "../../../../../Components/Input/Input";
import Button from "Components/Buttons/Button";
import withStore from "Components/Unstated/withStore";
import UserStore from "App/Auth/Store/UserStore";

const PasswordEntryForm = ({ isSubmitting }) => {
  return (
    <div className="passwordEntryForm">
      <div className="info">
        <h1>Create a password</h1>
      </div>
      <Form>
        <Field
          name={"email"}
          render={props => <Input {...props} placeholder={"Email"} disabled />}
        />
        <Field
          name={"password"}
          render={props => (
            <Input {...props} placeholder={"Password"} type={"password"} />
          )}
        />
        <Button
          onClick={() => {}}
          name={"Register"}
          type={"primary"}
          loading={isSubmitting}
          disabled={isSubmitting}
          buttonType={"submit"}
        />
      </Form>
    </div>
  );
};

export default withStore([UserStore])(
  withFormik({
    mapPropsToValues: ({ email }) => ({ email: email, password: "" }),
    validationSchema: () =>
      Yup.object().shape({
        email: Yup.string(string)
          .email(email)
          .required(isRequired("Email-ID")),
        password: Yup.string(string).required(isRequired("Password"))
      }),
    handleSubmit: async (
      values,
      {
        props: {
          name,
          email,
          phoneNumber,
          countryCode,
          userStore: { register }
        }
      }
    ) => {
      await register({ ...values, name, email, phoneNumber, countryCode });
    }
  })(PasswordEntryForm)
);
