import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Portal } from "react-portal";
import "./Modal.sass";

const Modal = ({ children, close, node }) => {
  useEffect(() => {
    const closeModal = e => {
      if (e.keyCode === 27) close();
    };

    document.addEventListener("keydown", closeModal, false);

    return () => document.removeEventListener("keydown", closeModal);
  }, [close]);

  //Todo: use node to show modals in fullscreen component during call
  return (
    <Portal>
      <div className="defaultModal" onClick={() => close()}>
        <div className="close" />
        <div className="defaultModalContent" onClick={e => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </Portal>
  );
};

Modal.propTypes = {
  children: PropTypes.any.isRequired,
  close: PropTypes.func.isRequired
};

export default Modal;
