import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
import "./Loading.sass";

const Loading = () => {
  return (
    <div className="loading">
      <PulseLoader color={"#3ecd7f"} size={10} />
    </div>
  );
};

export default Loading;
