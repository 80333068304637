export const roles = [
  {
    type: "Admin",
    icon: {
      type: "",
      name: ""
    },
    text: "Highest level of access, only admins add admins"
  },
  {
    type: "Manager",
    icon: {
      type: "",
      name: ""
    },
    text: "Can add payments and monitor fieldwork"
  },
  {
    type: "Professional",
    icon: {
      type: "",
      name: ""
    },
    text: "Can only access tasks and other fieldwork"
  }
  // {
  //   type: "Investor",
  //   icon: {
  //     type: "",
  //     name: ""
  //   },
  //   text: "Can provide funds & watch project's progress "
  // }
];
