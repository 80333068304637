import React, { useState } from "react";
import Button from "../../../../../../../../../Components/Buttons/Button";
import ContactList from "./ContactList";
import ContactPickerModal from "./ContactPickerModal";

const ContactPicker = ({ values, arrayHelpers }) => {
  const [showPicker, togglePicker] = useState(false);

  return (
    <div className="contactPicker">
      <ContactList arrayHelpers={arrayHelpers} contacts={values.contacts} />
      <Button
        onClick={() => togglePicker(true)}
        name={"Add Contacts"}
        type={"secondary"}
      />
      {showPicker && (
        <ContactPickerModal
          arrayHelpers={arrayHelpers}
          hidePicker={() => togglePicker(false)}
          contacts={values.contacts}
        />
      )}
    </div>
  );
};

export default ContactPicker;
