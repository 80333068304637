export const getField = (parent, key = "") => {
  if (parent === undefined || typeof parent !== "object") {
    return null;
  }

  const fields = key.split(".");

  if (fields.length > 1) {
    return fields[0].slice(-1) === "]"
      ? parent[fields[0].slice(0, fields[0].length - 3)]
        ? getField(
            parent[fields[0].slice(0, fields[0].length - 3)][
              parseInt(
                fields[0].slice(fields[0].length - 2, fields[0].length - 1)
              )
            ],
            fields.slice(1).join(".")
          )
        : null
      : parent[fields[0]]
      ? getField(parent[fields[0]], fields.slice(1).join("."))
      : null;
  } else if (fields.length === 1) {
    return fields[0].slice(-1) === "]"
      ? parent[fields[0].slice(0, fields[0].length - 3)]
        ? parent[fields[0].slice(0, fields[0].length - 3)][
            parseInt(
              fields[0].slice(fields[0].length - 2, fields[0].length - 1)
            )
          ] || null
        : null
      : parent[fields[0]] !== undefined
      ? parent[fields[0]]
      : null;
  }
  return null;
};

export const capitalize = (string = "") =>
  string
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

export const deepMemoCompare = (prev, next) =>
  JSON.stringify(prev) === JSON.stringify(next);

export const updatePathParams = (path = "", params = {}) => {
  let result = path;
  for (const key in params) {
    result = result.replace(`:${key}`, params[key]);
  }
  return result;
};

export const matchesRoute = (url, route, exact) =>
  exact ? url === route : url.startsWith(route);

export const getParamsFromUrl = (url, expectedFields = []) => {
  const params = new URL(url).searchParams;

  return expectedFields.map(field => params.get(field));
};

export const generateOptions = (options, labelKey, valueKey) =>
  options.map(value => ({
    label: labelKey ? value[labelKey] : value,
    value: valueKey ? value[valueKey] : value
  }));
