import { fieldworkStatuses } from "../Data";

export const parseValues = ({ category, description, status }) =>
  category === "Task"
    ? {
        category,
        description,
        status: fieldworkStatuses.find(({ name }) => name === status).code
      }
    : { category, description };
