import React from "react";
import "./ConversationsList.sass";
import ConversationElement from "./Components/ConversationElement";
import { Subscribe } from "unstated";
import MessagingStore from "Modules/Messaging/Stores/MessagingStore";

const ConversationsList = () => {
  return (
    <Subscribe to={[MessagingStore]}>
      {({ state: { conversations } }) => (
        <div className="conversationsList">
          {conversations.map(
            ({
              conversation: {
                _id: id,
                topMessage: { text, createdAt, user, files }
              },
              otherUsers: [
                {
                  displayName: name,
                  displayPicture: { thumbUrl }
                }
              ]
            }) => (
              <ConversationElement
                key={`Conversation-element-${id}`}
                user={{
                  name,
                  image: thumbUrl
                }}
                lastMessage={{
                  text,
                  createdAt,
                  files,
                  user
                }}
                id={id}
              />
            )
          )}
        </div>
      )}
    </Subscribe>
  );
};

export default ConversationsList;
