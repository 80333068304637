import { Api } from "./Configuration";

export const loginApi = uid =>
  Api.post("/users/login", { uid }).then(
    ({
      firebaseUid,
      displayName,
      email,
      emailVerificationStatus,
      countryCode,
      phoneWithoutCountryCode,
      _id,
      ...other
    }) => ({
      uid: firebaseUid,
      id: _id,
      name: displayName,
      email: email,
      emailVerified: emailVerificationStatus,
      phone: {
        countryCode: countryCode,
        number: parseInt(phoneWithoutCountryCode)
      },
      other
    })
  );

export const getCountryByIP = () =>
  Api.get(`https://ipapi.co/json`).then(res => res.country.toLowerCase());

export const registerApi = data => Api.post("/users", data);
