import React from "react";
import { Redirect, Switch, withRouter } from "react-router-dom";
import Route from "../../../../Components/Router/Route";
import ProjectsList from "./Components/ProjectList";
import Project from "./Components/Project";
import withStore from "../../../../Components/Unstated/withStore";
import ProjectsStore from "../../Store/ProjectsStore";

const Projects = ({ match: { url } }) => {
  return (
    <Switch>
      <Route exact path={`${url}`} render={() => <ProjectsList />} />
      <Route path={`${url}/:id`} render={() => <Project />} />
      <Route render={() => <Redirect to={url} />} />
    </Switch>
  );
};

export default withStore([ProjectsStore])(withRouter(Projects));
