import React from "react";
import date from "date-and-time";
import { Link, withRouter } from "react-router-dom";

const InvestmentElement = ({
  description,
  project,
  offering,
  milestones: { totalCost },
  _id: id,
  match: { url }
}) => {
  return (
    <Link to={`${url}/${id}`}>
      <div className="investmentElement">
        <div className="info">
          <div className="top">
            <div
              className="userImage"
              style={{
                background: `url(${project.user.displayPicture.thumbUrl}) center center /cover no-repeat`
              }}
            />
            <div className="user">
              <div className="name">{project.user.displayName}</div>
              <div className="date">
                {date.format(new Date(project.createdAt), `DD MMM YY`)}
              </div>
            </div>
          </div>
          <div className="bottom">
            <h1 className="project">{project.title}</h1>
            <p className="description">{description}</p>
          </div>
        </div>
        <div className="infoGraphic">
          <h1>Multiple</h1>
          <div className="icon" />
          <div className="value">
            {((offering * 100) / totalCost).toFixed(2)}%
          </div>
        </div>
      </div>
    </Link>
  );
};

export default withRouter(InvestmentElement);
