export const parseRegistrationData = ({
  name,
  email,
  phoneNumber,
  countryCode
}) => ({
  displayName: name,
  email,
  userType: null,
  phone:
    countryCode +
    phoneNumber
      .slice(1)
      .split(" ")
      .join(""),
  countryCode,
  phoneWithoutCountryCode: phoneNumber
    .slice(1)
    .split(" ")
    .join(""),
  firstName: name.split(" ")[0],
  lastName: name.split(" ").slice(-1)[0]
});
