import React from "react";
import { Link, withRouter } from "react-router-dom";
import { tabs } from "../Data/tabs";

const ProjectTabs = ({ match: { url }, location: { pathname } }) => {
  return (
    <div className="projectTabs">
      {tabs.map(({ route, name }, i) => (
        <Link to={url + route} key={`active-project-tab=el--${i}`}>
          <div
            className={"tabEl" + (pathname === url + route ? " active" : "")}
          >
            <div className="name">{name}</div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default withRouter(ProjectTabs);
