import React, { useEffect } from "react";
import "./Project.sass";
import { Portal } from "react-portal";
import { Redirect, Switch, withRouter } from "react-router-dom";
import withStore from "../../../../../../Components/Unstated/withStore";
import ProjectsStore from "../../../../Store/ProjectsStore";
import ProjectHeader from "./Components/ProjectHeader";
import ProjectTabs from "./Components/ProjectTabs";
import ComponentLoader from "../../../../../../Components/Loading/ComponentLoader";
import Route from "../../../../../../Components/Router/Route";
import Overview from "./Components/Overview";
import Transactions from "./Components/Transactions";
import Fieldwork from "./Components/Fieldwork";
import AddMember from "./Components/AddMember";
import CreateTransaction from "./Components/CreateTransaction";
import FloatingActionButton from "../../../../../../Components/Buttons/FloatingActionButton";
import CreateFieldwork from "./Components/CreateFieldwork";

const Project = ({
  projectsStore: {
    state: { loading, loadFailed, activeProject },
    setActiveProject
  },
  match: {
    url,
    params: { id }
  },
  history
}) => {
  useEffect(() => {
    setActiveProject(id, history);
    return () => !loading && setActiveProject(null);
  }, [setActiveProject, id, loading, history]);

  return (
    <Portal>
      <div className="projectWrapper">
        <div className="project">
          <ComponentLoader
            loading={loading && !activeProject}
            loadFailed={loadFailed}
          >
            {activeProject && (
              <>
                <ProjectHeader />
                <ProjectTabs />
                <Switch>
                  <Route exact path={`${url}`} render={() => <Overview />} />
                  <Route
                    path={`${url}/transactions`}
                    render={() => <Transactions />}
                  />
                  <Route
                    path={`${url}/fieldwork`}
                    render={() => <Fieldwork />}
                  />
                  <Route
                    path={`${url}/addMember`}
                    render={() => <AddMember />}
                  />
                  <Route
                    path={`${url}/addTransaction`}
                    render={() => <CreateTransaction />}
                  />
                  <Route
                    path={`${url}/createFieldwork`}
                    render={() => <CreateFieldwork />}
                  />
                  <Route render={() => <Redirect to={url} />} />
                </Switch>
                <Route
                  exact
                  path={`${url}`}
                  render={() => (
                    <FloatingActionButton
                      onClick={() => history.push(`${url}/addTransaction`)}
                      // options={[
                      //   {
                      //     icon: <span className="addMemberIcon" />,
                      //     label: "Add Member",
                      //     action: () => history.push(`${url}/addMember`)
                      //   },
                      //   {
                      //     icon: <span className="addTransactionIcon" />,
                      //     label: "Add Transaction",
                      //     action: () => history.push(`${url}/addTransaction`)
                      //   }
                      // ]}
                    />
                  )}
                />
              </>
            )}
          </ComponentLoader>
        </div>
      </div>
    </Portal>
  );
};

export default withStore([ProjectsStore])(withRouter(Project));
