import { Container } from "unstated";
import { getAllInvestments } from "../../../Api/Investment";

const initialState = () => ({
  loading: true,
  loadFailed: false,
  investments: [],
  activeInvestment: null
});

export default class InvestmentStore extends Container {
  name = "InvestmentStore";

  state = initialState();

  linkedStores = {};

  init = async () => {
    console.log("initializing InvestmentStore");

    const investments = await getAllInvestments();

    if (investments) {
      await this.setState({ investments, loading: false });
    } else {
      await this.setState({ loading: false, loadFailed: true });
    }
  };

  setActiveInvestment = async id => {
    const { investments } = this.state;

    const activeInvestment = investments.find(
      investment => investment._id === id
    );

    await this.setState({ activeInvestment });
  };

  bindStore = store => {
    this.linkedStores[store.name] = store;
  };
}
