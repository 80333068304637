import React from "react";
import { Link } from "react-router-dom";

const CreateHeader = () => {
  return (
    <div className="createHeader">
      <Link to={"/projects"}>
        <div className="back" />
      </Link>
      <h1>Create new project</h1>
    </div>
  );
};

export default CreateHeader;
