import React from "react";
import { dateFilters } from "../Data";

const DateFilter = ({ current, set }) => {
  return (
    <div className="dateFilter">
      {dateFilters.map((name, i) => (
        <div
          key={`date-filter-option-${i}`}
          className={"tabEl" + (name === current ? " active" : "")}
          onClick={() => set(name)}
        >
          <div className="icon" />
          <div className="name">{name}</div>
        </div>
      ))}
    </div>
  );
};

export default DateFilter;
