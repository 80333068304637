import React, { useEffect } from "react";
import "Styles/index.sass";
import { BrowserRouter, Switch } from "react-router-dom";
import AppStore from "./Store/AppStore";
import ComponentLoader from "../../Components/Loading/ComponentLoader";
import TopNav from "./Components/Navigation/TopNav";
import Route from "../../Components/Router/Route";
import Home from "../Home";
import Explore from "../Explore";
import Projects from "../Projects";
import Chat from "../Chat";
import Auth from "../Auth";
import BottomNav from "./Components/Navigation/BottomNav";
import Modules from "../../Modules";
import withStore from "../../Components/Unstated/withStore";
import TestPage from "../TestPage";
import Payment from "../Payment";

function App({
  appStore: {
    state: { loading, loadFailed },
    init
  }
}) {
  useEffect(() => {
    init();
  }, [init]);

  return (
    <ComponentLoader loading={loading} loadFailed={loadFailed}>
      <BrowserRouter>
        <div className="App">
          <TopNav />
          <Switch>
            <Route exact path={`/`} render={() => <Home />} />
            <Route path={`/explore`} render={() => <Explore />} isPrivate />
            <Route path={`/projects`} render={() => <Projects />} isPrivate />
            <Route path={`/chat`} render={() => <Chat />} isPrivate />
            <Route path={`/payment`} render={() => <Payment />} />
            <Route path={`/auth`} render={() => <Auth />} ifGuest />
            <Route path={`/test`} render={() => <TestPage />} />
          </Switch>
          <BottomNav />
          <Modules />
        </div>
      </BrowserRouter>
    </ComponentLoader>
  );
}

export default withStore([AppStore])(App);
