import React from "react";
import { Link } from "react-router-dom";
import { Subscribe } from "unstated";
import MessagingStore from "Modules/Messaging/Stores/MessagingStore";

const Heading = () => {
  return (
    <Subscribe to={[MessagingStore]}>
      {({
        state: {
          activeConversation: {
            otherUsers: [{ displayPicture, displayName }]
          }
        }
      }) => {
        return (
          <div className="heading">
            <Link to={"/chat"}>
              <div className="backButton" />
            </Link>
            <span
              className="image"
              style={{
                background: `url(${displayPicture.thumbUrl}) center center /cover no-repeat`
              }}
            />
            <h1>{displayName}</h1>
          </div>
        );
      }}
    </Subscribe>
  );
};

export default Heading;
