import React, { useRef, useState } from "react";
import { Subscribe } from "unstated";
import MessagingStore from "Modules/Messaging/Stores/MessagingStore";
import {disableParentTouchScroll} from "Modules/Messaging/Helpers";

const SendMessage = () => {
  const input = useRef(null);
  const [message, updateMessage] = useState("");

  return (
    <Subscribe to={[MessagingStore]}>
      {({ sendMessage }) => (
        <div className="sendMessage" {...disableParentTouchScroll}>
          <form
            onSubmit={async e => {
              e.preventDefault();
              e.stopPropagation();
              await sendMessage(message);
              input.current.focus();
              updateMessage("");
            }}
          >
            <input
              ref={input}
              type="text"
              value={message}
              onChange={e => updateMessage(e.target.value)}
              placeholder="Type a message..."
            />
            <input type="submit" value="" />
          </form>
        </div>
      )}
    </Subscribe>
  );
};

export default SendMessage;
