import React from "react";
import { categories } from "../Data";

const CategoryFilter = ({ current, set }) => {
  return (
    <div className="categoryFilter">
      {categories.map(({ name, filter }, i) => (
        <div
          key={`category-filter-option-${i}`}
          className={"tabEl" + (filter === current ? " active" : "")}
          onClick={() => set(filter)}
        >
          <div className="icon" />
          <div className="name">{name}</div>
        </div>
      ))}
    </div>
  );
};

export default CategoryFilter;
