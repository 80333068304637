import React from "react";
import "./Home.sass";
import Button from "Components/Buttons/Button";
import withStore from "Components/Unstated/withStore";
import UserStore from "App/Auth/Store/UserStore";
import { Link } from "react-router-dom";

const Home = ({
  userStore: {
    state: { user },
    logout
  }
}) => {
  return (
    <div className="home">
      <p>user: {user.name}</p>
      <p>email: {user.email}</p>
      <Button onClick={() => logout()} name={"logout"} type={"primary"} />

      <Link to={"/explore"}>
        <p>Explore</p>
      </Link>
      <Link to={"/projects"}>
        <p>Projects</p>
      </Link>
      <Link to={"/chat"}>
        <p>Chat</p>
      </Link>
    </div>
  );
};

export default withStore([UserStore])(Home);
