import React from "react";
import "./TestPage.sass";

const TestPage = () => {
  return (
    <div className="testPage">
      <p>Test Page</p>
    </div>
  );
};

export default TestPage;
