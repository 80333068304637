import { Container } from "unstated";
import {
  createProject,
  createProjectAction,
  findOrCreateUser,
  getProjects,
  inviteToProject
} from "../../../Api/Projects";
import { notify } from "../../../Modules/ToastNotification";
import { getTotalAmount } from "../Components/Projects/Components/Project/Components/CreateTransaction/Helpers";

const initialState = () => ({
  loading: true,
  loadFailed: false,
  projects: [],
  projectActionObjects: [],
  transactions: [],
  fieldwork: [],
  activeProject: null
});

export default class ProjectsStore extends Container {
  name = "ProjectsStore";

  state = initialState();

  linkedStores = {};

  init = async () => {
    console.log("initializing ProjectsStore");
    const { projects, activeProject } = this.state;

    await this.setState({
      ...initialState(),
      projects,
      activeProject,
      loading: !projects.length
    });

    const data = await getProjects();

    if (data) {
      const { projects, projectActionObjects } = data;
      const pao = projectActionObjects
        .reduce((merged, current) => {
          const projectName = projects.find(
            ({ _id }) => _id === current.projectId
          ).title;
          return [
            ...merged,
            ...current.projectActions.map(data => ({
              ...data,
              projectName,
              projectId: current.projectId
            }))
          ];
        }, [])
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      await this.setState({
        loading: false,
        projects,
        projectActionObjects: pao,
        transactions: pao.filter(({ type }) => type === "Payment"),
        fieldwork: pao.filter(({ type }) => type === "Fieldwork")
      });
      if (activeProject) {
        await this.setActiveProject(activeProject._id);
      }
    } else {
      await this.setState({ loading: false, loadFailed: true });
    }
  };

  setActiveProject = async (id, history) => {
    if (!id) {
      await this.setState({ activeProject: null });
      return;
    }

    const {
      loading,
      projects,
      transactions,
      fieldwork,
      activeProject
    } = this.state;
    if (loading) {
      return;
    }

    if (activeProject && activeProject._id === id) {
      return;
    }

    const project = projects.find(({ _id }) => _id === id);

    if (!project) {
      history.push("/projects");
    } else {
      await this.setState({
        activeProject: {
          ...project,
          transactions: transactions.filter(txn => txn.project === project._id),
          fieldwork: fieldwork.filter(
            fieldwork => fieldwork.project === project._id
          )
        }
      });
    }
  };

  createProject = async data => {
    const {
      state: {
        id,
        user: {
          phone: { countryCode, number }
        }
      }
    } = this.linkedStores.UserStore;

    const success = await createProject({
      ...data,
      participants: [id],
      phone: countryCode + number
    });

    if (success) {
      await this.init();
    }

    return success;
  };

  getUser = async (details, find = false) =>
    await findOrCreateUser(
      find
        ? { phone: details.phone }
        : {
            phone:
              details.countryCode +
              details.number
                .slice(1)
                .split(" ")
                .join(""),
            countryCode: details.countryCode,
            phoneWithoutCountryCode: details.number
              .slice(1)
              .split(" ")
              .join(""),
            firstName: details.name.split(" ")[0],
            lastName: details.name.split(" ").slice(-1)[0],
            displayName: details.name
          }
    );

  inviteUser = async (user, role) => {
    const { activeProject } = this.state;

    const success = await inviteToProject({
      userId: user._id,
      projectId: activeProject._id,
      countryCode: user.countryCode,
      phone: user.phone,
      role,
      message: " This is a trial message from ContractFlo"
    });

    if (success) {
      notify("success", `Invitation sent to ${user.displayName}`);
      await this.init();
    }

    return success;
  };

  createTransaction = async ({ items, category, currency, users }) => {
    const {
      activeProject: { _id: projectID }
    } = this.state;

    const success = await createProjectAction({
      type: "Payment",
      category,
      currency,
      price: Number(getTotalAmount(items, "").trim()),
      users,
      project: projectID,
      items: items.map(item => ({ ...item, currency }))
    });

    console.log({ success });

    if (success) {
      this.init();
    }

    return success;
  };

  createFieldwork = async ({
    category,
    status = "",
    description,
    users = []
  }) => {
    const {
      activeProject: { _id: projectID }
    } = this.state;

    const success = await createProjectAction({
      type: "Fieldwork",
      category,
      status,
      description,
      users,
      project: projectID
    });

    console.log({ success });

    if (success) {
      this.init();
    }

    return success;
  };
  bindStore = store => {
    this.linkedStores[store.name] = store;
  };
}
