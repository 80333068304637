import React from "react";
import "./BottomNav.sass";
import { Link, withRouter } from "react-router-dom";
import { matchesRoute } from "../../../../../Helpers/Misc";
import { blacklistedRoutes } from "./Config/blacklistedRoutes";
import { bottomNavLinks } from "./Config/links";

const BottomNav = ({ location: { pathname } }) => {
  return blacklistedRoutes.some(({ route, exact }) =>
    matchesRoute(pathname, route, exact)
  ) ? null : (
    <div className="bottomNav">
      {bottomNavLinks.map(({ name, route }, i) => (
        <Link to={route} key={`bottom-nav-link-${i}`}>
          <div
            className={"link" + (pathname.startsWith(route) ? " active" : "")}
          >
            <div className={"icon icon-" + name.toLowerCase()} />
            <p>{name}</p>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default withRouter(BottomNav);
