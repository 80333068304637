import React, { useState } from "react";
import { fixedSIP, oneTimeReturns } from "../../../Helpers";
import { Subscribe } from "unstated";
import InputRange from "react-input-range";
import InvestmentStore from "../../../Stores/InvestmentStore";
import "react-input-range/lib/css/index.css";
import Button from "../../../../../Components/Buttons/Button";
import Collapsible from "react-collapsible";

const InvestmentCalculator = () => {
  const [value, setValue] = useState(2000);
  const [time, setTime] = useState(6);
  const [mode, setMode] = useState("SIP");

  return (
    <Subscribe to={[InvestmentStore]}>
      {({
        state: {
          activeInvestment: {
            offering,
            milestones: { totalCost }
          }
        }
      }) => {
        const growth = Number(offering) / Number(totalCost);

        const oneTimeValue = oneTimeReturns(value, growth, time).toFixed(0);
        const sipValue = fixedSIP(value, growth, time, 1).toFixed(0);

        return (
          <div className="investmentCalculator">
            <Collapsible
              trigger={
                <div className={"trigger"}>
                  <h1>SIP and One-Time Calculator</h1> <span className="icon" />
                </div>
              }
            >
              <div className="modePicker">
                <h2
                  onClick={() => setMode("SIP")}
                  className={mode === "SIP" ? "active" : ""}
                >
                  SIP
                </h2>
                <h2
                  onClick={() => setMode("ONE-TIME")}
                  className={mode === "ONE-TIME" ? "active" : ""}
                >
                  ONE TIME
                </h2>
              </div>
              <h1>
                Investing ₹{value}{" "}
                {mode === "SIP" ? "per installment in SIP" : "once in ONE-TIME"}
              </h1>
              <div className="valuePicker">
                <InputRange
                  minValue={2000}
                  maxValue={1000000}
                  value={value}
                  onChange={setValue}
                />
              </div>

              <div className="timePicker">
                <Button
                  onClick={() => setTime(6)}
                  name={"6 Months"}
                  type={time === 6 ? "primary" : "secondary"}
                />
                <Button
                  onClick={() => setTime(9)}
                  name={"9 Months"}
                  type={time === 9 ? "primary" : "secondary"}
                />
                <Button
                  onClick={() => setTime(12)}
                  name={"12 Months"}
                  type={time === 12 ? "primary" : "secondary"}
                />
                <Button
                  onClick={() => setTime(15)}
                  name={"15 Months"}
                  type={time === 15 ? "primary" : "secondary"}
                />
              </div>

              <h1>
                Value could be ₹{mode === "SIP" ? sipValue : oneTimeValue}
              </h1>
            </Collapsible>
          </div>
        );
      }}
    </Subscribe>
  );
};

export default InvestmentCalculator;
