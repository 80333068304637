import React from "react";
import { Subscribe } from "unstated";
import ComponentLoader from "../../../../../../Components/Loading/ComponentLoader";
import ProjectsStore from "../../../../Store/ProjectsStore";
import ProjectListItem from "./Components/ProjectListItem";

const ProjectsList = () => {
  return (
    <div className="projectsList">
      <Subscribe to={[ProjectsStore]}>
        {({ state: { loading, loadFailed, projects } }) => (
          <ComponentLoader loading={loading} loadFailed={loadFailed}>
            <div className="projectsSection">
              {projects.map(({ _id, ...project }) => (
                <ProjectListItem
                  key={`project-list-item-${_id}`}
                  {...project}
                  id={_id}
                />
              ))}
            </div>
          </ComponentLoader>
        )}
      </Subscribe>
    </div>
  );
};

export default ProjectsList;
