import React from "react";
import Button from "Components/Buttons/Button";
import { Field, Form, withFormik } from "formik";
import Input from "Components/Input/Input";
import * as Yup from "yup";
import {
  email,
  isRequired,
  string
} from "Helpers/Form Validations/Validation Error Messages";

const UserDetailsForm = () => {
  return (
    <div className="userDetailsForm">
      <div className="info">
        <h1>Create an account</h1>
      </div>
      <Form>
        <Field
          render={props => <Input {...props} placeholder={"Name"} />}
          name={"name"}
        />
        <Field
          render={props => <Input {...props} placeholder={"Email"} />}
          name={"email"}
        />
        <Field
          render={props => (
            <Input
              {...props}
              type={"phone"}
              placeholder={"Phone Number"}
              countryCodeField={"countryCode"}
            />
          )}
          name={"phoneNumber"}
        />
        <Button
          onClick={() => {}}
          name={"Next"}
          type={"primary"}
          buttonType={"submit"}
        />
      </Form>
    </div>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    name: "",
    email: "",
    phoneNumber: "",
    countryCode: ""
  }),
  validationSchema: () =>
    Yup.object().shape({
      name: Yup.string(string).required(isRequired("Name")),
      email: Yup.string(string)
        .email(email)
        .required(isRequired("Email-ID")),
      phoneNumber: Yup.string(string).required(isRequired("Phone"))
    }),
  handleSubmit: (values, { props: { submit } }) => {
    submit(values);
  }
})(UserDetailsForm);
