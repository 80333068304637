import { Container } from "unstated";

const initialState = () => ({});

export default class PaymentStore extends Container {
  name = "PaymentStore";

  state = initialState();

  linkedStores = {};

  init = async () => {
    console.log("initializing PaymentStore");
  };

  processPayment = console.log;

  bindStore = store => {
    this.linkedStores[store.name] = store;
  };
}
