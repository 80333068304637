import React from "react";
import "./Create.sass";
import CreateHeader from "./Components/CreateHeader";
import CreateForm from "./Components/CreateForm";

const CreateProject = () => {
  return (
    <div className="createProject">
      <CreateHeader />
      <CreateForm />
    </div>
  );
};

export default CreateProject;
