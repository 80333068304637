import React from "react";
import Collapsible from "react-collapsible";
import { Subscribe } from "unstated";
import date from "date-and-time";
import InvestmentStore from "../../../Stores/InvestmentStore";

const ProjectMilestones = () => {
  return (
    <Subscribe to={[InvestmentStore]}>
      {({
        state: {
          activeInvestment: {
            milestones: { milestonesWithCost }
          }
        }
      }) => (
        <div className="projectMilestones">
          <Collapsible
            trigger={
              <div className={"trigger"}>
                <h1>Project Milestones</h1> <span className="icon" />
              </div>
            }
          >
            {milestonesWithCost.map((milestone, i) => (
              <ProjectMilestoneCard
                key={`project-milestone-card-${i}`}
                {...milestone}
              />
            ))}
          </Collapsible>
        </div>
      )}
    </Subscribe>
  );
};

export default ProjectMilestones;

const ProjectMilestoneCard = ({
  started,
  completed,
  cost,
  expectedCost,
  status,
  description
}) => (
  <div className="projectMilestoneCard">
    <div className="stats">
      <p className="status">
        {status === 2 ? "Completed" : status === 1 ? "Ongoing" : "Planned"}
      </p>
      <p className="amount">₹{cost || expectedCost}</p>
    </div>
    <p className="description">{description}</p>
    <div className="dates">
      <div className="icon" />
      <p className="start">{date.format(new Date(started), "DD MMM YY")}</p>
      <p className="end">{date.format(new Date(completed), "DD MMM YY")}</p>
    </div>
  </div>
);
