import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { roles } from "../Data";
import Button from "../../../../../../../../../Components/Buttons/Button";
import { Subscribe } from "unstated";
import ProjectsStore from "../../../../../../../Store/ProjectsStore";

const MemberRolePicker = ({ user, back, history, match: { url } }) => {
  const [selected, setSelected] = useState("");
  const [processing, setProcessing] = useState(false);

  return (
    <Subscribe to={[ProjectsStore]}>
      {({ inviteUser }) => (
        <div className="memberRolePicker">
          <h1>Inviting User</h1>
          <div className="member">
            <div
              className="icon"
              style={{
                background: `url(${user.displayPicture.thumbUrl}) center center /cover no-repeat`
              }}
            />
            <div className="info">
              <p className="name">{user.displayName}</p>
              <p className="contact">
                +{user.countryCode} {user.phone}
              </p>
            </div>
          </div>

          <h1>Select a role</h1>
          <div className="roles">
            {roles.map(({ type, text }, i) => (
              <div
                className={"role" + (selected === type ? " active" : "")}
                key={"member-roles-" + i}
                onClick={() => setSelected(type)}
              >
                <h1 className="name">{type}</h1>
                <p className="about">{text}</p>
              </div>
            ))}
          </div>
          <div className="actions">
            <Button onClick={() => back()} name={"Back"} type={"secondary"} />
            <Button
              onClick={async () => {
                setProcessing(true);
                const success = await inviteUser(user, selected);
                if (success) {
                  history.push(
                    url
                      .split("/")
                      .slice(0, -1)
                      .join("/")
                  );
                }
                setProcessing(false);
              }}
              name={"Send Invite"}
              type={"primary"}
              loading={processing}
              disabled={processing || !selected}
            />
          </div>
        </div>
      )}
    </Subscribe>
  );
};

export default withRouter(MemberRolePicker);
